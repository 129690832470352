import React, { FC, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { LabelCode } from './index.styled';
import Cookies from 'js-cookie';
import { Form } from 'react-final-form';
import { useMutation } from 'react-query';
import { userService } from 'shared/services/auth.service';
import { useAuth } from 'store/auth';
import DiiaButton from '../app-button/DiiaButton';
import {
  FormInput,
  FormInputPassword,
  FormInputPhoneCode,
} from '../form-input';
import { useNavigate } from 'react-router-dom';
import { validate } from './validate-schema';
import { fingerprintHandler } from '../../utils/fingerprint';

type SignUpForm = {
  phone: string;
};

const SignUpForm: FC<SignUpForm> = ({ phone }) => {
  const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(false);
  const [confirmError, setConfirmError] = useState('');
  const [visitorId, setVisitorId] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState<boolean>(false);

  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SECRET_KEY || '';

  useEffect(() => {
    const fetchFingerprint = async () => {
      const id = await fingerprintHandler();
      if (id) {
        setVisitorId(id);
      }
    };

    fetchFingerprint();
  }, []);

  const navigate = useNavigate();
  const cookieSetter = async (token: string) => {
    Cookies.set('token', token, {
      expires: 7,
      path: '/',
    });
  };

  const handleRecaptchaChange = async (token: string | null) => {
    if (token) {
      await verifyRecaptchaTokenHandle(token);
    }
  };

  const signup = useMutation(
    async (body: any) => userService.signupPhone(body),
    {
      onSuccess: async (res: any) => {
        await cookieSetter(res.data.access_token);

        useAuth.getState().updateAuth(true)();
        navigate('/');
      },
      onError: (error: any) => {
        console.log(error);

        let errorStr = '';
        error.response.data.message === 'Code does not match' &&
          console.log(
            (errorStr = 'Неправильні останні 4 цифри номера який вам дзвонив!')
          );

        // error.response.data.message === 'No record with presented code!' &&
        //   console.log((errorStr = 'За цим кодом немає записів!'));
        //
        // error.response.data.message ===
        //   'You have not access to this document type' &&
        //   console.log((errorStr = 'Не той документ!'));
        //
        // error.response.data.message === 'Failed validate document' &&
        //   console.log(
        //     (errorStr = 'Неправильні дані (код не прийшов валідацію)')
        //   );
        //
        // error.response.data.message === 'Not found user' &&
        //   console.log((errorStr = 'Користувача не існує.'));
        //
        // error.response.data.message === 'Not verify Email' &&
        //   console.log((errorStr = 'Пошта не підтверджена'));
        //
        // error.response.data.message === 'User is blocked.' &&
        //   console.log((errorStr = 'Користувач заблокован'));

        setConfirmError(errorStr);
        setIsLoginBtnDisabled(false);
      },
    }
  );

  const verifyRecaptchaToken = useMutation(
    async (body: any) => userService.verifyRecaptchaToken(body),
    {
      onSuccess: async (res: any) => {
        setRecaptchaToken(res?.data);
      },
      onError: (error: any) => {
        console.log(error);

        let errorStr = '';
        error.response.data.message === 'Invalid reCAPTCHA token' &&
          console.log((errorStr = 'Неправильна reCAPTCHA'));

        setConfirmError(errorStr);
        setIsLoginBtnDisabled(false);
      },
    }
  );

  const signupHandle = async (values: any) => {
    setIsLoginBtnDisabled(true);

    signup.mutate({
      phone: phone,
      phoneCode: values.phoneCode,
      email: values.email,
      fip: values.fip,
      password: values.password,
      visitorId: visitorId,
    });
  };

  const verifyRecaptchaTokenHandle = async (token: any) => {
    verifyRecaptchaToken.mutate({
      token: token,
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Form
          onSubmit={signupHandle}
          initialValues={{
            fip: '',
            email: '',
            password: '',
            phoneCode: '',
          }}
          validate={validate}
          render={({ handleSubmit, submitError }) => (
            <>
              <div
                style={{
                  flexWrap: 'wrap',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4px',
                }}
              >
                <div
                  style={{
                    flexGrow: '1',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <label>ПІБ</label>
                  <FormInput name="fip" passedValue=""></FormInput>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'end',
                  }}
                >
                  <label>Email </label>
                </div>
                <FormInput name="email" passedValue="" />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'end',
                  }}
                >
                  <label>Пароль </label>
                </div>
                <FormInputPassword name="password" passedValue="" />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'end',
                  }}
                >
                  <label>Підтвердіть пароль </label>
                </div>
                <FormInputPassword name="repeatPassword" passedValue="" />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <LabelCode>
                  <label>Код </label>
                  <label
                    style={{
                      fontSize: 12,
                      color: 'blue',
                    }}
                  >
                    * 4 останні цифри номеру телефона який вам дзвонив
                  </label>
                </LabelCode>
                <FormInputPhoneCode name="phoneCode" passedValue="" />
                <span style={{ color: 'red' }}>{confirmError}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'end',
                  }}
                >
                  <label>Підтвердіть, що ви людина </label>
                </div>
                <ReCAPTCHA
                  sitekey={recaptchaKey}
                  onChange={handleRecaptchaChange}
                />
              </div>
              <DiiaButton
                text="Зареєструватися"
                onClick={() => {
                  handleSubmit();
                }}
                icon={false}
                disabled={isLoginBtnDisabled || !recaptchaToken}
              ></DiiaButton>
            </>
          )}
        />
      </div>
    </>
  );
};

export default SignUpForm;
