/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import { COLORS, MEDIA, SPACES, FONTS } from '../../theme';

export const InputContainer = styled.div<{
  disabled: boolean | undefined;
  error: any;
}>`
  display: flex;
  position: relative;
  background-color: ${({ error, theme, disabled }) =>
    disabled
      ? theme.COLORS.disableColor
      : error
      ? theme.COLORS.errorSecondary
      : theme.COLORS.transparent};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.COLORS.errorBorder : theme.COLORS.greyPrimary};
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  @media only screen and (max-width: 561px) {
    margin-bottom: 8px;
  }
`;

export const InputWithIconContainer = styled.div<{
  disabled: boolean | undefined;
  error: any;
}>`
  display: flex;
  position: relative;
  align-items: center;
  background-color: ${({ error, theme }) =>
    error ? theme.COLORS.errorSecondary : theme.COLORS.transparent};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.COLORS.errorBorder : COLORS.greyPrimary};
  border-radius: 8px;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
`;

export const InputPrefix = styled.div`
  align-items: center;
  background-color: transparent;
  display: flex;
  padding-left: ${({ theme }) => theme.SPACES.xs};
`;

export const Input = styled.input<{ height?: number; error?: boolean }>`
  background-color: transparent;
  border: none;
  height: ${({ height }) => height}px;
  width: 90%;
  outline: none;
  box-sizing: border-box;
  border-color: ${({ error, theme }) =>
    error ? theme.COLORS.errorBorder : 'transparent'};
  &::placeholder {
    color: ${({ theme }) => theme.COLORS.greyPrimary};
    @media only screen and (max-width: 408px) {
      font-size: 11px;
    }
  }
`;

export const InputCode = styled.input<{ height?: number; error?: boolean }>`
  background-color: transparent;
  border: none;
  height: ${({ height }) => height}px;
  width: 90%;
  outline: none;
  box-sizing: border-box;
  padding-left: 8px;
  border-color: ${({ error, theme }) =>
    error ? theme.COLORS.errorBorder : 'transparent'};
  &::placeholder {
    color: ${({ theme }) => theme.COLORS.greyPrimary};
    @media only screen and (max-width: 408px) {
      font-size: 11px;
    }
  }
`;

export const InputForCode = styled.div<{ height?: number; error?: boolean }>`
  background-color: transparent;
  border: none;
  height: ${({ height }) => height}px;
  width: 60%;
  outline: none;
  box-sizing: border-box;
  margin: 0 0 0 ${({ theme }) => theme.SPACES.xs};
  border-color: ${({ error, theme }) =>
    error ? theme.COLORS.errorBorder : 'transparent'};
  &::placeholder {
    color: ${({ theme }) => theme.COLORS.greyPrimary};
  }
`;

export const ErrorInfoContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.COLORS.errorColor};
  font-size: 14px;
  line-height: 150%;
  margin: 5px 0 0 10px;
`;

export const ErrorInfoText = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  color: red;
  margin-left: ${({ theme }) => theme.SPACES.xxxs};
  display: flex;
`;

export const ErrorInfoTextPassword = styled.div`
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.FONTS.xxs};
  margin-left: ${({ theme }) => theme.SPACES.xxxs};
  display: flex;
  padding-right: 10px;
`;

export const InputWithIcon = styled.div`
  margin: 0 0 0 ${({ theme }) => theme.SPACES.xs};
  width: 85%;
`;

export const AutocompleteInput = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  cursor: text;
`;

export const Image = styled.img`
  margin: 0 5px 0 0;
`;

export const ImagePassword = styled.img`
  margin: 0 20px 0 0;
  float: right;
  fill: #fff;
  stroke: #000;
  &:hover {
    filter: invert(9%) sepia(99%) saturate(5630%) hue-rotate(246deg)
      brightness(80%) contrast(148%);
  }
`;

export const ImageResetCode = styled.img`
  margin: 0 20px 0 0;
`;

export const ResetCode = styled.div`
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.COLORS.green};
  margin: 0 22px 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    margin: 0 0 0 7px;
  }
  &:hover {
    color: ${({ theme }) => theme.COLORS.greenSecondary};
  }
  &:hover ${ImageResetCode} {
    filter: contrast(50%);
  }
}
`;

export const ImagePasswordPhone = styled.img`
  position: absolute;
  right: 10px;
  cursor: pointer;
`;

export const Styled = {
  InputContainer,
  InputPrefix,
  Input,
  InputCode,
  ErrorInfoContainer,
  ErrorInfoText,
  ErrorInfoTextPassword,
  AutocompleteInput,
  Image,
  ImagePassword,
  InputWithIconContainer,
  InputWithIcon,
  ImagePasswordPhone,
  ResetCode,
  ImageResetCode,
  InputForCode,
};
