import React, { useState } from 'react';
import ArticleCard from '../../../shared/components/article-card/article';
import {
  ArticlesContainer,
  TabButton,
  TabButtons,
  TabContainer,
  TabContent,
} from './user-tab.styles';
import sliderImag1 from '../../../assets/image/image1.png';
import { useNavigate } from 'react-router-dom';
import WebinarCard from '../../../shared/components/webinar-card';
import { RouterKeys } from '../../../router/keys';
import LinkIcon from 'shared/components/link-icon';

const UserTab = ({ userData }: any) => {
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const chooseArticleHandle = (id: number) => {
    navigate(`/articles/${id}`);
  };

  const webinarHandler = (id: any) => {
    navigate(`/${RouterKeys.WEBINARS}/${id}`);
  };

  return (
    <TabContainer>
      <TabButtons>
        <TabButton onClick={() => handleTabClick(1)} isActive={activeTab === 1}>
          Статті
        </TabButton>
        <TabButton onClick={() => handleTabClick(2)} isActive={activeTab === 2}>
          Вебінари
        </TabButton>
      </TabButtons>
      <TabContent>
        {activeTab === 1 && (
          <ArticlesContainer>
            {userData?.favorites_articles.length == 0 ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <div>Наразі у вас немає збережених статей</div>

                <LinkIcon text="Перейти до статтів" href={'../articles'} />
              </div>
            ) : (
              userData?.favorites_articles.map((article: any) => {
                return (
                  <ArticleCard
                    onClick={chooseArticleHandle}
                    key={article.id}
                    id={article.id}
                    name={article.author}
                    category={article.categories[0]}
                    title={article.title}
                    image={article.featured_image}
                  />
                );
              })
            )}
          </ArticlesContainer>
        )}
        {activeTab === 2 && (
          <ArticlesContainer>
            {userData?.webinars.length == 0 ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <div>Наразі у вас немає куплених вибінарів</div>

                <LinkIcon text="Перейти до вебінарів" href={'../webinars'} />
              </div>
            ) : (
              userData?.webinars.map((item: any) => {
                return (
                  <WebinarCard
                    key={item.title}
                    onClick={webinarHandler}
                    id={item.id}
                    author={item.author}
                    title={item.title}
                    category={item.categories[0].name}
                    showedButton={false}
                    price={item.price}
                    imageUrl={item.featured_image}
                  />
                );
              })
            )}
          </ArticlesContainer>
        )}
      </TabContent>
    </TabContainer>
  );
};
export default UserTab;
