import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { ErrorInfoTextPassword, InputCode, Styled } from './form-input.styled';
import aye from '../../../assets/icons/aye.svg';
import closeAye from '../../../assets/icons/closeAye.svg';

export interface IFormInputProps {
  name: string;
  type?: string;
  placeholder?: string;
  step?: number;
  min?: number;
  maxLength?: number;
  disabled?: boolean | undefined;
  backError?: string | null;
  passedValue: string;
  height?: number;
  icon?: any;
}

export const FormInputPhoneCode: React.FC<IFormInputProps> = ({
  name,
  placeholder,
  disabled,
  passedValue,
  icon,
  backError = null,
  height = 48,
}) => {
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  return (
    <>
      <Styled.InputContainer disabled={disabled} error={Boolean(isError)}>
        {icon && icon}
        <Field
          name={name}
          render={({ input, meta }) => {
            if (meta.error && meta.touched && !isError) {
              setErrorTitle(meta.error);
              setIsError(true);
            } else if (!meta.error && isError) {
              setErrorTitle('');
              setIsError(false);
            }
            return (
              <>
                <Styled.InputCode
                  id={name}
                  type="text"
                  {...input}
                  value={input.value || passedValue}
                  placeholder={placeholder}
                  maxLength={4}
                  disabled={disabled}
                  height={height}
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </>
            );
          }}
        />
      </Styled.InputContainer>
      {isError && <Styled.ErrorInfoText>* {errorTitle}</Styled.ErrorInfoText>}
    </>
  );
};

export const FormInput: React.FC<IFormInputProps> = ({
  name,
  type,
  placeholder,
  step,
  min,
  maxLength,
  disabled,
  passedValue,
  icon,
  backError = null,
  height = 48,
}) => {
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  return (
    <>
      <Styled.InputContainer
        disabled={disabled}
        error={Boolean(isError || backError)}
      >
        <Styled.InputPrefix />
        {icon && icon}
        <Field
          name={name}
          render={({ input, meta }) => {
            if (meta.error && meta.touched && !isError) {
              setErrorTitle(meta.error);
              setIsError(true);
            } else if (!meta.error && isError) {
              setErrorTitle('');
              setIsError(false);
            }
            return (
              <>
                <Styled.Input
                  id={name}
                  type={type}
                  min={min || 0.01}
                  step={step}
                  {...input}
                  value={input.value || passedValue}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  disabled={disabled}
                  height={height}
                />
              </>
            );
          }}
        />
      </Styled.InputContainer>
      {isError && <Styled.ErrorInfoText>* {errorTitle}</Styled.ErrorInfoText>}
    </>
  );
};

export const FormInputPassword: React.FC<IFormInputProps> = ({
  name,
  type,
  placeholder,
  step,
  min,
  maxLength,
  disabled,
  passedValue,
  icon,
  backError = null,
  height = 48,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  return (
    <>
      <Styled.InputContainer disabled={disabled} error={Boolean(isError)}>
        <Styled.InputPrefix />
        {icon && icon}
        <Field
          name={name}
          render={({ input, meta }) => {
            if (meta.error && meta.touched && !isError) {
              setErrorTitle(meta.error);
              setIsError(true);
            } else if (!meta.error && isError) {
              setErrorTitle('');
              setIsError(false);
            }
            return (
              <>
                <Styled.Input
                  id={name}
                  type={isOpen ? 'text' : 'password'}
                  min={min || 0.01}
                  step={step}
                  {...input}
                  value={input.value || passedValue}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  disabled={disabled}
                  height={height}
                />
              </>
            );
          }}
        />
        <Styled.ImagePasswordPhone
          onClick={() => setIsOpen(!isOpen)}
          src={isOpen ? aye : closeAye}
          alt="toggle password visibility"
        />
      </Styled.InputContainer>
      {isError && <Styled.ErrorInfoText>* {errorTitle}</Styled.ErrorInfoText>}
    </>
  );
};

export const FormInputWithIcon: React.FC<IFormInputProps> = ({
  name,
  placeholder,
  step,
  min,
  maxLength,
  disabled,
  passedValue,
  icon,
  backError = null,
  height = 48,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Styled.InputWithIconContainer
        disabled={disabled}
        error={Boolean(backError)}
      >
        {icon && icon}
        <Styled.InputWithIcon>
          <Field
            name={name}
            render={({ input, meta }) => (
              <>
                <Styled.Input
                  id={name}
                  type={isOpen ? 'text' : 'password'}
                  min={min || 0.01}
                  step={step}
                  {...input}
                  value={input.value || passedValue}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  disabled={disabled}
                  height={height}
                  error={meta.error}
                />
              </>
            )}
          />
        </Styled.InputWithIcon>
        {isOpen ? (
          <Styled.ImagePassword
            onClick={() => setIsOpen(!isOpen)}
            src={aye}
            alt="aye"
          />
        ) : (
          <Styled.ImagePassword
            onClick={() => setIsOpen(!isOpen)}
            src={closeAye}
            alt="aye"
          />
        )}
      </Styled.InputWithIconContainer>
      {/*{backError && (*/}
      {/*  <Styled.ErrorInfoContainer>*/}
      {/*    <Styled.ErrorInfoText>{backError}</Styled.ErrorInfoText>*/}
      {/*  </Styled.ErrorInfoContainer>*/}
      {/*)}*/}
    </>
  );
};
