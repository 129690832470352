import React, { FC, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Cookies from 'js-cookie';
import { Form } from 'react-final-form';
import { useMutation } from 'react-query';
import { userService } from 'shared/services/auth.service';
import { useAuth } from 'store/auth';
import DiiaButton from '../app-button/DiiaButton';
import { FormInput, FormInputPassword } from '../form-input';
import { useNavigate } from 'react-router-dom';
import { validate } from './validate-schema';
import { SideText } from '../../../modules/auth/login/index.styles';
import ResetPasswordForm from '../reset-password-phone';
import { fingerprintHandler } from '../../utils/fingerprint';

type SignInForm = {
  phone: string;
};

const SignInForm: FC<SignInForm> = ({ phone }) => {
  const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(false);
  const [confirmError, setConfirmError] = useState('');
  const [resetPassword, setResetPassword] = useState(false);
  const [visitorId, setVisitorId] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState<boolean>(false);

  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SECRET_KEY || '';

  useEffect(() => {
    const fetchFingerprint = async () => {
      const id = await fingerprintHandler();
      if (id) {
        setVisitorId(id);
      }
    };

    fetchFingerprint();
  }, []);

  const navigate = useNavigate();
  const cookieSetter = async (token: string) => {
    Cookies.set('token', token, {
      expires: 7,
      path: '/',
    });
  };

  const handleRecaptchaChange = async (token: string | null) => {
    if (token) {
      await verifyRecaptchaTokenHandle(token);
    }
  };

  const login = useMutation(
    async (body: any) => userService.loginByPhone(body),
    {
      onSuccess: async (res: any) => {
        await cookieSetter(res.data.access_token);

        useAuth.getState().updateAuth(true)();
        navigate('/');
      },
      onError: (error: any) => {
        console.log(error);

        let errorStr = '';
        error.response.data.message === 'Invalid password' &&
          console.log((errorStr = 'Невірний пароль!'));
        error.response.data.message === 'User not found' &&
          console.log(
            (errorStr = 'Користувача с таким номером телефона не знайдено!')
          );

        error.response.data.message === 'Wrong print' &&
          console.log(
            (errorStr = `Реєстрацію облікового запису через номер телефону, було зроблено не з даного пристрою (комп'ютер, телефон).\n Напишіть в тех.підтримку!`)
          );

        setConfirmError(errorStr);
        setIsLoginBtnDisabled(false);
      },
    }
  );

  const resetPasswordPhone = useMutation(async (body: any) =>
    userService.resetPasswordPhone(body)
  );

  const verifyRecaptchaToken = useMutation(
    async (body: any) => userService.verifyRecaptchaToken(body),
    {
      onSuccess: async (res: any) => {
        setRecaptchaToken(res?.data);
      },
      onError: (error: any) => {
        console.log(error);

        let errorStr = '';
        error.response.data.message === 'Invalid reCAPTCHA token' &&
          console.log((errorStr = 'Неправильна reCAPTCHA'));

        setConfirmError(errorStr);
        setIsLoginBtnDisabled(false);
      },
    }
  );

  const loginHandle = async (values: any) => {
    setIsLoginBtnDisabled(true);

    login.mutate({
      phone: phone,
      password: values.password,
      visitorId: visitorId,
    });
  };

  const resetPasswordHandler = async () => {
    resetPasswordPhone.mutate({
      phone: phone,
    });
    setResetPassword(true);
  };

  const verifyRecaptchaTokenHandle = async (token: any) => {
    verifyRecaptchaToken.mutate({
      token: token,
    });
  };

  return (
    <>
      {!resetPassword && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Form
            onSubmit={loginHandle}
            initialValues={{
              password: '',
            }}
            validate={validate}
            render={({ handleSubmit, submitError }) => (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignContent: 'end',
                    }}
                  >
                    <label>Пароль </label>
                  </div>
                  <FormInputPassword
                    type="password"
                    name="password"
                    passedValue=""
                  />

                  <span style={{ color: 'red' }}>{confirmError}</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignContent: 'end',
                    }}
                  >
                    <label>Підтвердіть, що ви людина </label>
                  </div>
                  <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={handleRecaptchaChange}
                  />
                </div>
                <DiiaButton
                  text="Увійти?"
                  onClick={() => {
                    handleSubmit();
                  }}
                  icon={false}
                  disabled={isLoginBtnDisabled || !recaptchaToken}
                ></DiiaButton>
                <SideText>
                  Забули пароль?{' '}
                  <span
                    onClick={resetPasswordHandler}
                    style={{
                      fontSize: 14,
                      color: 'blue',
                      cursor: 'pointer',
                    }}
                  >
                    Відновити
                  </span>
                </SideText>
              </>
            )}
          />
        </div>
      )}
      {resetPassword && <ResetPasswordForm phone={phone} />}
    </>
  );
};

export default SignInForm;
