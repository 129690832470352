import styled from 'styled-components';
import { COLORS } from '../../../shared/theme';

export const MailForm = styled.div`
  margin-bottom: 16px;
`;

export const InputForm = styled.input`
  font-size: 20px;
  padding: 8px 6px 8px 6px;
  border-width: 1px;
  &:focus {
    outline: none;
  }
`;

export const LabelCode = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: end;
  @media only screen and (max-width: 490px) {
    flex-wrap: wrap;
  }
`;
